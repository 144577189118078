var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-0 m-0"},[_c('div',[_c('b-table',{staticClass:"position-relative text-center patient-appoinment",attrs:{"fields":_vm.fields,"striped":"","bordered":"","responsive":"","hover":"","items":_vm.paginatedItems},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(row){return [_c('b-form-checkbox',{staticClass:"vs-checkbox-con",attrs:{"plain":""},on:{"change":row.toggleDetails},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}})]}},{key:"row-details",fn:function(row){return [_c('b-tabs',[_c('b-tab',{staticStyle:{"width":"75vw !important"},attrs:{"active":"","title":"Invoice"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12"}},[_c('RecipePatient',{attrs:{"AppointmentId":row.item.id}})],1)],1)],1),_c('b-tab',{attrs:{"title":"Information appointment"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4","lg":"6","cols":"7"}},[(row.item.office_visit && row.item.office_visit.nurses)?_c('b-card',{staticClass:"text-center"},[_c('b-avatar',{staticClass:"mb-1",attrs:{"size":"30","src":row.item.office_visit.nurses.avatar}}),_c('div',{staticClass:"text-center d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("NURSE")]),_c('b-link',{attrs:{"to":{
                        name: 'admin-users-view',
                        params: { id: row.item.office_visit.nurses.id },
                      }}},[_c('span',[_vm._v(" "+_vm._s(row.item.office_visit.nurses.name)+" "+_vm._s(row.item.office_visit.nurses.lastname)+" ")])])],1)],1):_vm._e(),_c('b-card',{attrs:{"title":"Procedure Timeline"}})],1),_c('b-col',{attrs:{"md":"8","lg":"6","cols":"7"}},[(row.item.office_visit && row.item.office_visit.omas)?_c('b-card',{staticClass:"text-center"},[_c('b-avatar',{staticClass:"mb-1",attrs:{"size":"30","src":row.item.office_visit.omas.avatar}}),_c('div',{staticClass:"text-center d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("OMA")]),_c('b-link',{attrs:{"to":{
                        name: 'admin-users-view',
                        params: { id: row.item.office_visit.omas.id },
                      }}},[_c('span',[_vm._v(" "+_vm._s(row.item.office_visit.omas.name)+" "+_vm._s(row.item.office_visit.omas.lastname)+" ")])])],1)],1):_vm._e(),_c('b-card',{staticClass:"card-transaction",attrs:{"title":"Vital signs"}},[_c('b-row',_vm._l((row.item.office_visit.type_vital_sign),function(item,i){return _c('b-col',{key:i,attrs:{"lg":"4","md":"3","xl":"3"}})}),1)],1)],1)],1)],1)],1)]}},{key:"cell(type_visits)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(data.item.type_visits.name),expression:"data.item.type_visits.name",modifiers:{"hover":true,"top":true}}],staticClass:"badge-glow",style:({ 'background-color': data.item.type_visits.color })},[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(" "+_vm._s(data.item.type_visits.prefix)+" ")])])],1)]}},{key:"cell(type_consultations)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item.type_visits && data.item.type_visits.name ? data.item.type_visits.name : "Not specified")+" ")])]}},{key:"cell(facilities)",fn:function(data){return [_c('b-link',{attrs:{"to":{
            name: 'admin-facilities-view',
            params: { id: data.item.facilities.id },
          }}},[_c('span',[_vm._v(_vm._s(data.item.facilities.name))])])]}},{key:"cell(frontdesks)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.frontdesks.avatar}})]},proxy:true}],null,true)},[_c('b-link',{attrs:{"to":{
              name: 'admin-users-view',
              params: { id: data.item.frontdesks.id },
            }}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.frontdesks.name)+" "+_vm._s(data.item.frontdesks.lastname)+" ")])])],1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[(
              data.item.office_visit_insurance &&
              data.item.office_visit_insurance.visit_statuses_id === 5
            )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Refund'),expression:"'Refund'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon btn-circle border-0",attrs:{"variant":"outline-warning"},on:{"click":function($event){return _vm.refundAppointment(data.item.id)}}},[_c('i',{staticClass:"fas fa-exchange-alt"})]):_vm._e(),(
              data.item.office_visit_insurance &&
              [1, 6, 7].includes(+data.item.office_visit_insurance.visit_statuses_id)
            )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Delete'),expression:"'Delete'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon btn-circle border-0",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteAppointment(data.item.id)}}},[_c('i',{staticClass:"far fa-trash-alt"})]):_vm._e()],1)]}}])}),_c('PaginationTable',{attrs:{"dataMeta":_vm.dataMeta,"totalUsers":_vm.totalUsers,"perPage":_vm.perPage,"currentPage":_vm.currentPage},on:{"page-changed":_vm.pageChanged}})],1),(_vm.conditionFacilityAppointment)?_c('ChangeFacility',{attrs:{"appointmentById":_vm.appointmentById},on:{"closeModalFacility":function($event){_vm.conditionFacilityAppointment = false},"NotificationCheck":_vm.NotificationCheck}}):_vm._e(),(_vm.conditionRefund)?_c('RefundAppointment',{attrs:{"appointmentById":_vm.appointmentById},on:{"closeModalPay":function($event){_vm.conditionRefund = false},"NotificationCheck":_vm.NotificationCheck}}):_vm._e(),(_vm.conditionOmaAppointment)?_c('ChangeOma',{attrs:{"appointmentById":_vm.appointmentById},on:{"closeModalFacility":function($event){_vm.conditionOmaAppointment = false},"NotificationCheck":_vm.NotificationCheck}}):_vm._e(),_c('ToastNotification',{ref:"toast"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }